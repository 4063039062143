<script>
import AppBanner from '@/components/shared/AppBanner';
import ProjectsGrid from '../components/networks/ProjectsGrid.vue';
import ToolsGrid from '../components/tools/ToolsGrid.vue';
import Button from '../components/reusable/Button.vue';

export default {
  name: 'Home',
  components: {
    AppBanner,
    ProjectsGrid,
    ToolsGrid,
    Button,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <!-- Banner -->
    <AppBanner class="mb-5 sm:mb-8" />
  </div>
  <div class="bg-gray-100 pb-20 dark:bg-secondary-dark">
    <!-- Projects -->
    <ProjectsGrid class="container mx-auto" />

    <!-- Load more projects button -->
    <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
        to="/networks"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Projects"
      >
        <Button title="More Projects" />
      </router-link>
    </div>
  </div>
  <div class="container mx-auto pb-40">
    <ToolsGrid />
  </div>
</template>

<style scoped></style>
