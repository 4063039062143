<script>
import feather from 'feather-icons';
import ToolSingle from './ToolSingle.vue';
import tools from '../../data/tools';

export default {
	components: { ToolSingle },
	data: () => {
		return {
			tools,
			toolsHeading: 'Tools I Built',
		};
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<section class="pt-10 sm:pt-14">
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ toolsHeading }}
			</p>
		</div>

		

		<div
			class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 mt-6 sm:gap-10"
		>
			<ToolSingle
				v-for="tool in tools"
				:key="tool.id"
				:tool="tool"
			/>
		</div>
	</section>
</template>

<style scoped></style>
