<script>
export default {
	props: ['tool'],
};
</script>

<template>
	<a
		:href="tool.url"
		target="_blank"
		class="rounded-xl shadow-lg transition duration-500 hover:shadow-xl cursor-pointer mb-10 sm:mb-0 mr-2 bg-secondary-light dark:bg-ternary-dark hover:scale-110"
		aria-label="Single Project"
	>
    <div class="text-center px-4 pt-4">
			<p
				class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
			>
				{{ tool.title }}
			</p>
		</div>
		<div class="group relative">
			<img
				:src="tool.img"
				:alt="tool.title"
				class="rounded-t-xl border-none px-10 pb-5"
			/>
            <div class="font-general opacity-0 pb-5 group-hover:opacity-100 text-ternary-dark dark:text-ternary-light">
                {{tool.info}}
            </div>
		</div>
	</a>
</template>

<style lang="scss" scoped></style>
