const tools = [
	{
		id: 1,
		title: 'Airdrop Checker',
		img: require('@/assets/images/gift.svg'),
		info:'Cosmos Airdrop Checker',
		url: 'https://cosmosdrops.xyz/'
	},
	{
		id: 2,
		title: 'Distribute',
		img: require('@/assets/images/send.svg'),
		info:'Cosmos-based tool that distributes tokens to multiple addresses',
		url: 'https://ericet.github.io/distribute/'
	},
	{
		id: 3,
		title: 'Explorer',
		img: require('@/assets/images/explore.svg'),
		info:'Cosmos-based Network Explorer',
		url: 'https://explorer.ericet.xyz'
	},
	{
		id: 4,
		title: 'Vote',
		img: require('@/assets/images/vote.svg'),
		info:'Cosmos-based Auto Vote',
		url: 'https://ericet.github.io/cosmos-vote/#/'
	},
	{
		id: 4,
		title: 'Stake',
		img: require('@/assets/images/stake.svg'),
		info:'Native staking and governance UI for the TERITORI network',
		url: 'https://teritori.ericet.xyz'
	}
];

export default tools;
