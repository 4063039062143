<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'ericet.xyz',
			author: 'Ericet',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light">
			&copy; {{ projectName }} {{ copyrightDate }}. All rights reserved
		</div>
	</div>
</template>

<style lang="scss" scoped>
</style>
