// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Evmos',
		category: 'Mainnets',
		apr:'~160%',
		img: require('@/assets/images/evmos.png'),
		url:'https://evmos.disperze.network/validators/evmosvaloper15ksyw7t5t9e7w9n3d2jqqmv7y334mfrhfvvnge'
	},
	{
		id: 2,
		title: 'Likecoin',
		category: 'Mainnets',
		apr:'~19%',
		img: require('@/assets/images/likecoin.png'),
		url:'https://stake.like.co/validators/likevaloper1c0j6rurlnktya3qhwaw6jp4tzakyzf0026qnpd'
	},
	{
		id: 3,
		title: 'Desmos',
		category: 'Mainnets',
		apr:'~19%',
		img: require('@/assets/images/desmos.png'),
		url:'https://ping.pub/desmos/staking/desmosvaloper1dgyml4cgqp3qelx7nmnc34a7zz63vzk7htzkvx'
	},
	{
		id: 4,
		title: 'Nomic',
		category: 'Mainnets',
		apr:'~209%',
		img: require('@/assets/images/nomic.jpg'),
		url:'https://app.nomic.io/#/staking'
	},
	{
		id: 5,
		title: 'Cerberus',
		category: 'Mainnets',
		apr:'~24%',
		img: require('@/assets/images/cerberus.png'),
		url:'https://www.mintscan.io/cerberus/validators/cerberusvaloper198ljzq359ygqauxargj7je28sxlnj0tj2h7m73'
	},
	{
		id: 6,
		title: 'IDEP',
		category: 'Mainnets',
		apr:'~312%',
		img: require('@/assets/images/idep.jpg'),
		url:'https://chadscan.com/accounts/idep1z2avpy5japj45vs2vagtgenk62zpx9hvhsask8'
	},
	{
		id: 7,
		title: 'Canto',
		category: 'Mainnets',
		apr:'?%',
		img: require('@/assets/images/canto.jpg'),
		url:'https://staking.canto.io/'
	},
	{
		id: 8,
		title: 'Teritori',
		category: 'Mainnets',
		apr:'560%',
		img: require('@/assets/images/teritori.jpg'),
		url:'https://teritori.ericet.xyz/validators/torivaloper1a7taydvzhkd5vrndlykqtj7nsk2erdp2aaeqq0'
	},
	{
		id: 9,
		title: 'Rebus',
		category: 'Mainnets',
		apr:'560%',
		img: require('@/assets/images/rebus.png'),
		url:'https://explorer.ericet.xyz/rebus/staking'
	},
	{
		id: 10,
		title: 'Jackal',
		category: 'Mainnets',
		apr:'60%',
		img: require('@/assets/images/jackal.jpg'),
		url:'https://jackal.ericet.xyz/validators/jklvaloper1cp07k4ueagl98nk5q0n2vz2yrvwgnw5ckznd9m'
	}
];

export default projects;
